.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  padding: 20px;
  .left {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      margin: 10px 0;
    }
  }
  .right {
    width: 73%;
  }
}
.submit-button {
  display: block;
  min-width: 800px;
}
.image {
  img {
    width: 240px;
    height: 240px;
    border-radius: 16px;
  }
}
.team-member {
  margin-top: 20px;
}
.phone {
  display: flex;
  flex-direction: row;
}
.user-premium {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  .followers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 20px 0 10px;
    align-items: center;
    width: 100%;
    h1 {
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      color: #8d6cfa;
    }
  }
  .no-follow {
    font-size: 14px;
    color: #8b82a7;
  }
}

.form-style {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.button-red {
  color: red !important;
  padding: 10px 25px;
  font-size: 15px;
  width: 200px;
  height: 40px;
  margin-top: 20px;
  transition: 0.4s;
  border-color: red;
  font-weight: 500;
}

.session-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
}

.session {
  width: 100%;
  margin: 10px 0;
  border-top: 0.3px solid #8b82a7;
  padding: 5px 0;
  h3 {
    text-align: center;
    margin-bottom: 5px;
  }
}
.button-red:hover {
  background-color: red;
  color: white !important;
}
.button {
  padding: 10px 25px;
  font-size: 15px;
  width: 210px;
  height: auto;
  margin-top: 10px;
  margin-right: 30px;
  transition: 0.4s;
  font-weight: 500;
}
.details {
  margin-top: 60px;
  h1 {
    margin-bottom: 10px;
  }
}

.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.no-update {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  h1 {
    font-size: 32px;
  }
  span {
    font-size: 18px;
    color: #8b82a7;
  }
}

.time {
  display: block;
}
.session-name {
  color: #8d6cfa;
}

.referredBy {
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}

.dummyAvatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #f3f0fe;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  color: #5e4cc9;
  text-transform: uppercase;
  font-size: 50px;
  padding: 12px;
}
