.root {
  background: #fff;
  padding: 40px;
}

.header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    width: max-content;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
  }
  h1 {
    color: var(--daba-dark-base, #16054e);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
}

.formHeader {
  color: var(--daba-dark-base, #16054e);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  margin-bottom: 20px;
  align-self: center;
}

.tableRow {
  cursor: pointer;
}

.rootModal {
  max-height: 900px;
  overflow: auto;
  .inputNumber {
    width: 400px;
    height: 42px;
    background: #f8f8fd;
  }
  .input {
    max-width: 400px;
    margin-bottom: 20px;
  }
  display: flex;
  align-items: center;
  flex-direction: column;
  h2 {
    font-size: 26px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
  }
  .confirm {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  }
}

.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  margin-left: 25px;
  width: 95%;
  margin-bottom: 20px;
  display: flex;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

h2 {
  margin: 20px 0;
  font-size: 20px;
}

.tableContainer {
  width: 100%;
  margin-top: 80px;
  .table {
    background-color: #fff;
    width: 100%;
    overflow-x: scroll;
  }
}

.filterSection {
  display: flex;
  position: relative;
  align-items: flex-start;
  h2 {
    flex: 1;
    margin-left: 100px;
  }
  .txButton {
    width: max-content;
    padding: 0 10px;
    font-size: 15px;
    margin-top: 10px;
    margin-left: 10px;
  }
  .filter {
    margin-left: 20px;
    display: flex;
    padding: 8px 16px !important;
    height: 50px;
    justify-content: center;
    cursor: pointer;
    gap: 8px;
    flex-direction: row;
    transition: 0.5s ease-in-out;
    border: 1px solid #ded7f6;
    border-radius: 32px;
    align-items: center;
    h2 {
      color: #16054e;
      font-size: 14px;
      font-style: normal;
      text-align: center;
      margin: 0;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
    }
  }
  .filter:hover {
    opacity: 0.5;
  }
}

.filterItems {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 1000;
  border-radius: 16px;
  padding: 15px;
  width: 400px;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  h3 {
    margin: 20px 0 10px;
  }
}

.confirmText {
  margin: 20px 0;
}
