.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    margin-right: 20px;
  }
  margin-bottom: 30px;
}

.bigWidth {
  width: 300px;
  margin-bottom: 40px;
}

.width {
  width: 190px;
}

.add {
  color: #8d6cfa;
  font-weight: bold;
  border: 1px solid #8d6cfa;
  cursor: 'pointer';
  width: max-content;
  margin-bottom: 20px;
  padding: 5px 10px;
  border-radius: 8px;
  h2 {
    font-size: 14px;
    margin: 0;
  }
}

.fees {
  display: flex;
  border-radius: 14px;
  margin-bottom: 20px;
  grid-gap: 10px;
  padding: 10px;
  background: white;
  align-items: center;
  gap: 10px;
  width: 70%;
  padding: 20px;
  img {
    margin-left: 120px;
    cursor: pointer;
  }
  img:hover {
    opacity: 0.5;
  }
}

.add-button {
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid #8d6cfa;
  width: 150px;
  align-items: center;
  padding: 5px 10px;
  transition: 0.3s ease-in-out;
  border-radius: 8px;
  img {
    margin-right: 10px;
  }
  :hover {
    opacity: 0.5;
  }
}

.rate {
  display: flex;
  align-items: center;
  gap: 20px;
  .values {
    margin-bottom: 40px;
    p {
      font-weight: bold;
      color: green;
    }
    span {
      color: black;
      padding-left: 10px;
      font-size: 15px;
    }
  }
}

.modalContainer {
  .modalTitle {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    h1 {
      font-size: 24px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  .currentRate {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 250px;
    p {
      font-size: 35px;
      font-weight: 600;
    }
  }
}

.feeSchedule {
  background: #fff;
  margin-bottom: 20px;
}
