.record-label {
  display: flex;
  flex-direction: row;
  width: 100px;
  img {
    width: 32px;
    margin-right: 10px;
    height: 32px;
    border-radius: 100px;
  }
}

.active-status {
  background: #eaf9ea;
  color: #25ad25;
  padding: 5px 13px;
  border-radius: 16px;
}
.user-table {
  overflow-x: scroll;
}
.table {
  background: white;
}

.inactive-status {
  background-color: #f6f6f9;
  color: #5c5083;
  padding: 5px 10px;
  border-radius: 16px;
}

.container {
  background: #ffffff;
  padding: 20px;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h1 {
      margin: 20px 0;
    }
  }
  .payment-method {
    display: flex;
    justify-content: center;
  }
}
.loading {
  display: flex;
  flex-direction: column;
  margin-top: 259px;
  justify-content: center;
  align-items: center;
}
.no-update {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  h1 {
    font-size: 32px;
  }
  span {
    font-size: 18px;
    color: #8b82a7;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    cursor: pointer;
    margin-left: 20px;
  }
}
.logo {
  display: flex;
  flex-direction: row;
  width: 100px;
  align-items: center;
  img {
    width: 25px;
    margin-right: 10px;
    border-radius: 16px;
  }
}

.details-toggler {
  background: #ffffff;
  padding: 4px 25px;
  margin-left: 25px;
  width: 100%;
  display: flex;
  height: 64px;

  > span {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    border-bottom: 4px solid #ffffff;
    align-items: center;
    transition: 0.4s;
    font-weight: 500;
    padding: 4px 4px;
    margin-right: 20px;
    cursor: pointer;
    color: #8b82a7;
  }
  .active-toggle {
    color: #8d6cfa;
    border-color: #8d6cfa;
  }
}

.name {
  cursor: pointer;
  transition: 0.5s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
}

.nav-buttons {
  margin: 30px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.page-number {
  margin: 0 20px;
  color: #8d6cfa;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
}
.record-label {
  display: flex;
  flex-direction: row;
  width: 130px;

  img {
    width: 32px;
    margin-right: 10px;
    height: 32px;
    border-radius: 100px;
  }
}

.hold {
  display: flex;
  float: right;
  height: 35px;
  margin-bottom: 20px;
  gap: 20px;
}

.tableContainer {
  overflow-x: scroll;
  width: 100%;
}

.createModal {
  padding: 20px;
  h1 {
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
  }
}

.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.flexed {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  align-items: center;
}

.smallField {
  width: 250px;
  margin-bottom: 20px;
}

.field {
  width: 650px;
  margin-bottom: 20px;
  margin-bottom: 20px;
}

.input-number {
  width: 250px;
  padding: 4px;
  border-radius: 8px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
  &:hover,
  &:focus {
    border-color: #8d6cfa;
  }
}

.summary {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
  p {
    border-bottom: 1px solid #ded7f6;
    padding: 10px 0;
  }
  strong {
    margin-right: 10px;
  }
}

.form {
  max-height: 700px;
  overflow-y: auto;
}

.notes {
  margin-top: 20px;
  background-color: #f8f8fd;
  padding: 10px;
  border-radius: 8px;
  width: max-content;
  padding: 10px 20px;
  border-radius: 10px;
  max-width: 90%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  p {
    font-size: 13px;
  }
}

.record-label {
  display: flex;
  flex-direction: row;
  width: 130px;

  img {
    width: 32px;
    margin-right: 10px;
    height: 32px;
    border-radius: 100px;
  }
}
