@import '../node_modules/hamburgers/dist/hamburgers';
@import './globalstyles/variables';
@import '~@dabafinance/react-components/lib/index.css';
@import '../node_modules/@dabafinance/react-components/lib/index.css';

:root {
  --toastify-icon-color-success: #fff;
  --toastify-toast-min-height: auto;
  --toastify-toast-width: 500px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Manrope';
  src: url('/fonts/Manrope-VariableFont_wght.ttf') format('truetype-variations');
  font-weight: 500 800; /* Define the range of weights available in the font */
  font-style: normal;
}

@import '~react-toastify/dist/ReactToastify.css';

html {
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
  height: auto;
}

body,
* {
  font-family: 'Manrope', sans-serif;
}

h1 {
  font-weight: 700 !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  width: 100%;
  height: 100%;
  background-color: $bodyBg;
}

html._fixed,
body._fixed {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}

#root {
  max-width: 1366px;
  margin: 0 auto;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #8d6cfa;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
}

p {
  font-size: 16px;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  outline: none;
  font-family: inherit;
  white-space: nowrap;
  text-decoration: none;
}

button {
  outline: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
  border: 0;
}

img {
  object-fit: cover;
  display: block;
}

.hamburger {
  padding: 0;
  width: fit-content;
}

.hamburger-box {
  border: none;
  outline: none;
  padding: 0;
  width: 30px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: $primary;
  width: 30px;
  height: 2px;
  margin: 0;
}

.query-loading {
  width: 100%;
  height: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.small-query-loader {
  width: 100%;
  height: 100%;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.query .hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: $primary;
  width: 30px;
  height: 2px;
  margin: 0;
}

.hamburger:hover {
  opacity: 1 !important;
}

.swiperPrev,
.swiperNext {
  width: 32px;
  height: 32px;
  color: $dark;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $secondary;
  border-radius: 32px;
  font-size: 10px;
  cursor: pointer;
}

.swiperPrev {
  margin-right: 16px;
}

.ant-collapse {
  background-color: transparent;

  .ant-collapse-content-box {
    padding: 0px;
  }
}

.ant-collapse-header {
  background: none;
  border: none;
  padding: 0px !important;
  .ant-collapse-header-text {
    color: #16054e;
  }

  .ant-collapse-arrow {
    margin-right: 0px !important;
  }
}
.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.select-option-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

// storybook hacks!
.db-reactions {
  &:hover {
    .db-reaction-others {
      width: auto;
    }
  }
}

.db-watchlist-header-text-wrap {
  @media only screen and (max-width: 800px) {
    margin-left: 10px;
  }
}

.on-row {
  cursor: pointer;
}

.db-search-wrapper {
  label {
    @media only screen and (max-width: 600px) {
      left: 16px;
    }

    img {
      object-fit: contain;
    }
  }

  input {
    font-size: 16px;

    @media only screen and (max-width: 600px) {
      padding: 12px 16px 12px 45px;
    }
  }
}

.country-report-card {
  width: 100%;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;

    .db-country-report-text {
      margin: 0;
      margin-top: 16px;
      text-align: center;
    }
  }
}

.input-number {
  width: 300px;
  padding: 4px;
  border-radius: 8px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
  &:hover,
  &:focus {
    border-color: #8d6cfa;
  }
}

.not-allowed {
  cursor: not-allowed !important;
}

.toast {
  width: 500px;
  &.Toastify__toast-body {
    padding: 0px !important;
  }
  &.Toastify__toast--error {
    background-color: #ee0020;
    color: white;
  }

  &.Toastify__toast--success {
    background-color: #25ad25;
    color: white;
  }
}

.horizontal-label {
  display: flex !important;
  width: 500px;
  flex-direction: row !important;
}

.finishScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: max-content;
  h1 {
    margin-top: 30px;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0 !important;
  }
  p {
    margin: 5px;
    font-size: 17px;
    text-align: center;
  }
  .finish-items {
    margin-top: 40px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 20fr);
    gap: 20px;
    place-items: center;
    margin-left: auto;
    margin-right: auto;
    .items {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    p {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  .finish-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-end;
    flex: 1;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .finish-button {
    font-size: 18px;
    margin-right: 10px;
    width: 150px;
    height: 50px;
    transition: 0.3s;
    font-weight: bold;
  }
}
.ant-pagination-options-quick-jumper {
  margin-top: 5px;
  margin-left: 10px;
}

.ant-table-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.buttons {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 20px;
  button {
    width: 150px;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
    transition: 0.3s ease-in-out;
  }
}

.antd-table {
  overflow-y: auto;
  background-color: #fff;
}

.confirm-modal {
  display: flex;
  flex-direction: column;
  min-height: 250px;
  align-items: center;
  width: 100%;
  justify-content: center;
  div {
    text-align: center;
    h1 {
      font-size: 25px;
      margin-bottom: 20px;
      margin-top: 10px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

.navigateBack {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  transition: 0.4s ease-in-out;
  div {
    display: flex;
    align-items: center;
    gap: 10px;
    opacity: 0.5;
    cursor: pointer;
  }
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    :hover {
      opacity: 0.7;
    }
  }
  h1 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }
}
