.matchEngine {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  justify-content: space-between;
  p {
    font-weight: 600;
    font-size: 14;
  }
}

.resetEngine {
  display: flex;
  gap: 10px;
  align-items: center;
}

.refresh {
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  transition: 0.5s ease-in-out;
  &:hover {
    opacity: 0.5;
  }
}
