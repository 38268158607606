.form-item {
  width: 500px;
  margin-bottom: 25px;
}
.container {
  background: #fff;
  padding: 30px;
  h1 {
    margin-bottom: 30px;
  }
}
.card-item {
  width: 300px;
}

// .card-number {
//   min-width: 300px;
//   padding: 4px;
//   border-radius: 8px;
//   background-color: #f8f8fd;
//   border: 1px solid #ded7f6;
//   &:hover,
//   &:focus {
//     border-color: #8d6cfa;
//   }
// }
.form {
  display: flex;
  flex-direction: column;
}
.card-container {
  display: flex;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-bottom: 30px;
  border-radius: 16px;
  justify-content: space-between;
  max-width: 750px;
  padding: 20px;
}
.delete-icon {
  cursor: pointer;
}
.left-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  margin: 20px 0;
  cursor: pointer;
  h2 {
    color: #8d6cfa;
    font-weight: 600;
    font-size: 14px;
    margin-left: 5px;
  }
}
.venture-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  min-height: 500px;
  margin-top: 40px;
}
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.add-faq {
  display: flex;
  flex-direction: row;
  width: max-content;
  align-items: center;
  cursor: pointer;
  margin: 20px 0;
  span {
    color: #8d6cfa;
    font-size: 15px;
    margin-left: 10px;
    font-weight: 700;
  }
}

.rule {
  width: 600px;
  margin: 20px;
  align-self: center;
}

.vectorContainer {
  display: flex;
  width: 70%;
  gap: 20px;
  flex-direction: column;
}

.vectorFields {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-shadow: rgba(155, 152, 152, 0.15) 1.95px 1.95px 2.6px;
  padding: 10px;
  border-radius: 8px;
  justify-content: space-between;
}

.vector-single {
  display: flex;
  align-items: start;
  flex-direction: column;
}

.magnitude {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
