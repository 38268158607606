.root {
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.input-number {
  width: 220px;
  padding: 4px;
  border-radius: 8px;
  height: 45px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
  &:hover,
  &:focus {
    border-color: #8d6cfa;
  }
}

.infoHeader {
  display: flex;
  align-items: center;
  background-color: #ece9f5;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 22px;
  margin: 30px 0;
  border-radius: 12px;
  gap: 10px;
  span {
    font-weight: bold;
    font-style: normal;
  }
  p {
    font-style: italic;
  }
}

.smallWidth {
  width: 230px;
}
