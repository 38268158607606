.top {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px;
  }
}

.form {
  background: #fff;
  padding: 25px;
}

.fieldWidth {
  width: 500px;
  margin-bottom: 20px;
}

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px;
  }
}

.nav-buttons {
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.page-number {
  margin: 0 20px;
  color: #8d6cfa;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
}

.topButton {
  display: flex;
  flex-direction: row-reverse;
  margin: 30px;
  button {
    width: max-content;
    padding: 0 20px;
    font-size: 15px;
    font-weight: bold;
    height: 45px;
    transition: 0.3s ease-in-out;
  }
}

.fees {
  display: flex;
  max-width: 60%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.smallWidth {
  width: 170px;
}
