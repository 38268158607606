.root {
  background: #fff;
  padding: 20px;
}

.smallWidth {
  width: 300px;
  margin-bottom: 20px;
}

.largeWidth {
  width: 700px;
  margin-bottom: 20px;
}

.form {
  width: 65%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.input-number {
  width: 310px;
  padding: 4px;
  border-radius: 8px;
  height: 45px;
  background-color: #f8f8fd;
  border: 1px solid #ded7f6;
  &:hover,
  &:focus {
    border-color: #8d6cfa;
  }
}

.fundManager {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .managerDetails {
    width: 60%;
  }
}

.bigWidth {
  width: 700px;
  margin-bottom: 15px;
}

.smallWidth {
  width: 320px;
  margin-bottom: 15px;
}

.flexed {
  display: flex;
  flex-direction: row;
  width: 68%;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.infoHeader {
  display: flex;
  align-items: center;
  background-color: #ece9f5;
  width: 70%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 22px;
  margin: 20px 0;
  border-radius: 12px;
  gap: 10px;
  span {
    font-weight: bold;
    font-style: normal;
  }
  p {
    font-style: italic;
  }
}

.list {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  margin-bottom: 20px;
  width: 55%;
  padding: 10px;
  gap: 24px;
}

.bigList {
  width: 70%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  margin-bottom: 20px;
  padding: 10px;
  gap: 24px;
}

.listField {
  margin-bottom: 20px;
}

h3 {
  text-transform: capitalize;
  margin-top: 50px;
}
.add-item {
  display: flex;
  border: 1px solid #8d6cfa;
  border-radius: 8px;
  width: max-content;
  margin: 20px 0;
  padding: 10px;
  gap: 10px;
  color: #8d6cfa;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
}
.all-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.dateWidth {
  width: 400px;
}

.fundWidth {
  width: 400px;
  margin-bottom: 10px;
}
